import { Region } from '../components/utils/Region';
import { EnumTypeOf } from '../components/utils/types/EnumTypeOf';

// revalidate every 8 hours
export const REVALIDATE_TIME = 28800;

export const GEOLOCATION_UNKNOWN = Region.UNKNOWN;

/**
 * Query string parameters handled by the website.
 */
export const enum QueryStringParam {
	PARTNER = 'partner',
	DYNAMIC_HERO_TITLE = 'dht',
	EMAIL_JOURNEY = 'emailJourney',
	PHONE = 'phone',
	IS_TIMELY_USER = 'IsTimelyUser',
}

/**
 * Cookies set and checked by the website.
 */
export const enum Cookie {
	TIMELY_RESELLER = 'TimelyReseller',
	TIMELY_REFERRER = 'TimelyReferrer',

	TIMELY_USER = 'tu',
	TIMELY_AREA_CODE = 'TimelyAC', // This cookie is used for GB and IE area redirects
	TIMELY_COUNTRY_CODE = 'TimelyCC',
}

/**
 * Local storage keys used by the website
 */
export const enum LocalStorageKey {
	LC = 'lc',
}

/**
 * The app uses integer IDs for each business category.
 */
export const enum BusinessCategoryID {
	OTHER = 1,
	HAIR = 2,
	BEAUTY = 3,
	MASSAGE_DAY_SPA = 4,
	/** @deprecated This has been replaced with the `PERSONAL_TRAINING` category */
	GROUP_FITNESS = 5,
	HEALTH_WELLBEING_CLINIC = 6,
	TUTORING_CONSULTING = 7,
	HAIR_AND_BEAUTY = 12,
	BARBER = 13,
	BODY_ART = 14,
	PERSONAL_TRAINING = 15,
}

/**
 * The app uses integer IDs for each business size category.
 */
export const enum BusinessSizeID {
	/** Solo */
	SOLO = 1,
	/** 2 - 7 staff */
	SMALL = 2,
	/** 8 - 20 staff */
	MEDIUM = 3,
	/** Over 20 staff */
	LARGE = 4,
}

export const WPPageID = {
	ABOUT: '63445',
} as const;
export type WPPageID = EnumTypeOf<typeof WPPageID>

///////////////////////
// Marketo constants //
///////////////////////

export const MARKETO_BASE_URL = '//go.gettimely.com';
export const MARKETO_MUNCHKIN_ID = '304-RPU-875';

export const enum MarketoFormId {
	TYRO = '1262',
	LAYBUY = '1197',
	SIGN_UP = '1184',
}
