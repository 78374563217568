import React, {
	createContext,
	useContext,
	useState,
	useEffect,
} from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import {
	QueryStringParam,
	Cookie,
	LocalStorageKey,
	GEOLOCATION_UNKNOWN,
} from '../../lib/constants';
import cookieManager from '../utils/cookie';
import getQueryStringParams from '../utils/get-query-string-params';
import { setGeolocation, gdprRegions } from '../utils/DisplayRegion';

import type { TimelyUser } from './TimelyUser';
import { isTimelyUser } from './TimelyUser';
import { EnumTypeOf } from 'components/utils/types/EnumTypeOf';

/**
 * Create a persistent handle to this Promise resolver so it can be resolved externally.
 */
let resolveGeolocationPromise: (geolocation: string) => void;
/**
 * Create a persistent handle to this Promise so it can be returned from `getLocation`.
 */
const geolocationPromise: Promise<string> = new Promise((resolve, reject) => {
	resolveGeolocationPromise = resolve;
});

type TimelyMethods = {
	setLocalStorage(this: void, data: Record<string, string>): void,
	setLocation(this: void, location: string, user: TimelyUser): void,
	getLocation(this: void): Promise<string>,
	setPageContext<Prop extends keyof TimelyUser>(this: void, key: Prop, value: NonNullable<TimelyUser[Prop]>, user: TimelyUser): void,
	isCustomer(this: void): boolean,
};

type TimelyUserWithMethods = TimelyUser & TimelyMethods;

declare global {
	interface Window {
		tu?: TimelyUserWithMethods,
		lc?: TimelyUser,
	}
}

// Initialise with a stub context
export const PageContext = createContext<TimelyUserWithMethods>({
	setLocalStorage() {},
	setLocation() {},
	getLocation() { return geolocationPromise; },
	setPageContext() {},
	isCustomer() { return false; },
});


/**
 * This is reverse engineered, so may be missing possible values
 */
const BusinessStatus = {
	TRIAL_ENDED: 'TrialEnded',
	TRIALING: 'Trialing',
	TEST: 'Test',
	ACTIVE: 'Active',
} as const;
type BusinessStatus = EnumTypeOf<typeof BusinessStatus>;
function setPageClasses(geolocation?: string) {
	const classes = [];
	if (geolocation) {
		classes.push(geolocation);
	}
	const htmlEl = document.body;

	if (
		!geolocation ||
		geolocation === GEOLOCATION_UNKNOWN ||
		(gdprRegions as readonly unknown[]).includes(geolocation)
	) {
		classes.push('gdpr');
		classes.push('has-gdpr');
		htmlEl.classList.remove('nogdpr');
		htmlEl.classList.remove('no-gdpr');
		// redirectGB(geolocation);
		// redirectXero();
	} else {
		classes.push('nogdpr');
		classes.push('no-gdpr');
		htmlEl.classList.remove('gdpr');
		htmlEl.classList.remove('has-gdpr');

		const privacyChecks = document.querySelectorAll<HTMLInputElement>('input[type=text]');
		for (let i = 0; i < privacyChecks.length; i++) {
			privacyChecks[i].checked = true;
		}
	}

	htmlEl.classList.add(...classes);
}

const whitelist = [
	'email',
	'staffCount',
	'name',
	'planRate',
	'fname',
	'lname',
	'bsize',
	'emailJourney',
	'website',
	'sfrom',
	'industry',
	'tel',
	'telmulti',
	'country',
	'main-source',
	'sub-source',
	'geolocation',
	'webinar',
	'businessCategory',
	'businessSize',
	'timely-user',
	'business-name',
	'preferredTime',
	'contactID',
	'ReferrerID',
] as const;

// Stub localStorage API to prevent errors on old browsers
if (typeof window !== 'undefined' && !window.localStorage) {
	window.localStorage = {
		getItem() { return '{}'; },
		setItem() {},
		removeItem() {},
		clear() {},
		key() { return ''; },
		length: 0,
	};
}

const defaultProfile: TimelyUser = {};

const propTypes = {
	defaultUser: PropTypes.shape({}).isRequired,
	children: PropTypes.shape({}).isRequired,
};
type PageProviderProps = InferProps<typeof propTypes>;

export function PageProvider({ defaultUser, children }: PageProviderProps) {
	const initialUser: TimelyUser = { ...defaultProfile, ...defaultUser };
	const [user, setUser] = useState<TimelyUser | null>(initialUser);

	function setLocalStorage(this: void, data: Partial<TimelyUser>) {
		const localStorageUserString = localStorage.getItem(LocalStorageKey.LC);
		let localStorageUser: TimelyUser = {};
		if (typeof localStorageUserString === 'string' && localStorageUserString.length > 0) {
			const localStorageUserObj = JSON.parse(localStorageUserString) as unknown;
			if (isTimelyUser(localStorageUserObj)) {
				localStorageUser = localStorageUserObj;
			}
		}

		whitelist.map((key) => {
			if (data[key]) {
				localStorageUser[key] = data[key];
			}
		});

		localStorage.setItem(LocalStorageKey.LC, JSON.stringify(localStorageUser));
		window.lc = localStorageUser;
	}

	function setLocation(this: void, location: string, user: TimelyUser) {
		user.geolocation = location;
		setUser(user);
	}

	function getLocation(): Promise<string> {
		return geolocationPromise;
	}

	function setPageContext<Prop extends keyof TimelyUser>(this: void, key: Prop, value: NonNullable<TimelyUser[Prop]>, user: TimelyUser) {
	// user[key] = value;
		const newUser: TimelyUser = {
			...user,
		};
		newUser[key] = value;
		setUser(newUser);
		// console.log({ key, value });
		setLocalStorage({ [key]: value });
	}

	const setPageRedirects = (geolocation?: string) => {
		if (window && window.location.pathname === '/' && (geolocation === 'GB' || geolocation === 'IE')) {
			// @ts-expect-error Setting `window.location` to a `string` has special behaviour in some cases, so I'm not confident refactoring this to set `window.location.href` is safe
			window.location = `${process.env.NEXT_PUBLIC_ROOTURL}gb`;
		}
	};

	/**
	 * Returns whether or not the current user is known to be a customer (including trialists).
	 * 
	 * Reverse-engineered from `action.jsx`
	 */
	const isCustomer = (): boolean => {
		const customerStatuses: BusinessStatus[] = [
			BusinessStatus.TRIAL_ENDED,
			BusinessStatus.TRIALING,
			BusinessStatus.TEST,
			BusinessStatus.ACTIVE,
		];
		
		const business_status = user?.business_status;

		const isCustomer = (customerStatuses as unknown[]).includes(business_status);

		return isCustomer;
	};

	const methods: TimelyMethods = {
		setLocalStorage,
		setLocation,
		getLocation,
		setPageContext,

		isCustomer,
	};

	async function getUser() {
		try {
			let profile: TimelyUser = {};

			const tu = cookieManager.get(Cookie.TIMELY_USER);
			if (tu) {
				const cookieUser = JSON.parse(tu) as unknown;
				if (isTimelyUser(cookieUser)) {
					profile = cookieUser;
					cookieManager.set(Cookie.TIMELY_USER, JSON.stringify(profile), 1);
				}
			} else {
				try {
					const response = await fetch(
						// `${process.env.NEXT_PUBLIC_ROOTURL}st/cookies/2/`,
						`${process.env.NEXT_PUBLIC_ROOTURL}timely-api/tu-cookies/`,
						{
							mode: 'no-cors', // no-cors, *cors, same-origin
						}
					);
					const responseText = await response.text();
					const responseObj = JSON.parse(responseText) as unknown;

					if (isTimelyUser(responseObj)) {
						profile = responseObj;
					}
				} catch (err) {
					console.error('TU cookie fetch error: ' + err);
				}
			}

			const cookieGeo = cookieManager.get(Cookie.TIMELY_AREA_CODE);
			if (cookieGeo) {
				profile.geolocation = cookieGeo;
				resolveGeolocationPromise(profile.geolocation);
			} else {
				const response = await fetch('https://www.gettimely.com/cdn-cgi/trace');

				try {
					const res = await response.text();
					let country_code: string = GEOLOCATION_UNKNOWN;
					const lines = res.split('\n');
					let keyValue;
					const trace: Record<string, string> = {};

					// lines may not be available,
					if (lines.length > 0) {
						lines.forEach(line => {
							keyValue = line.split('=');
							trace[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
							if (keyValue[0] === 'loc' && trace['loc'] !== 'XX') {
								country_code = trace['loc'];
							}
						});
					}

					const TimelyCC = country_code === GEOLOCATION_UNKNOWN ? 'US' : country_code;
					cookieManager.set(Cookie.TIMELY_COUNTRY_CODE, TimelyCC, 7);
					cookieManager.set(Cookie.TIMELY_AREA_CODE, country_code, 7); // Where TimelyCC stored a fallback to US for gdpr TimelyAC will store Unknown when not set
					profile.geolocation = country_code;
					resolveGeolocationPromise(profile.geolocation);
				} catch (err) {
					console.error(err);
				}
			}

			setPageRedirects(profile.geolocation);
			setPageClasses(profile.geolocation);
			setGeolocation(profile.geolocation);
			let localStorageUser: TimelyUser = {};

			const localStorageUserString = localStorage.getItem(LocalStorageKey.LC);
			if (localStorageUserString && localStorageUserString.length > 0) {
				const localStorageUserObj = JSON.parse(localStorageUserString) as unknown;
				if (isTimelyUser(localStorageUserObj)) {
					localStorageUser = localStorageUserObj;
				}
			}

			if (profile.error) {
				setUser(null);
			} else {
				const newUser = {
					...user,
					...profile,
					...localStorageUser,
					geolocation: profile.geolocation,
					...methods,
				};

				if (window) {
					window.tu = newUser;
				}
				setUser(newUser);
			}
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * Initialise the reseller cookie based on the querystring
	 */
	function setResellerCookie() {
		const queryParams = getQueryStringParams(document.location.search);
		const partner = queryParams[QueryStringParam.PARTNER];
		if (partner) {
			const partnerCookieOptions = {
				domain: /(^|\.)gettimely\.com/.test(document.location.hostname) ? '.gettimely.com' : undefined,
			};
			cookieManager.set(Cookie.TIMELY_RESELLER, partner, 90, partnerCookieOptions);
		}
	}

	useEffect(
		() => {
			getUser();

			setResellerCookie();
		},
		// The linting rule expects `getUser` to be specified as a dependency, but because it uses a `setState` function this causes an infinite loop
		// eslint-disable-next-line
		[],
	);

	return (
		<PageContext.Provider value={{ ...user, ...methods }}>{children}</PageContext.Provider>
	);
}

export const Page = () => useContext(PageContext);
