export type TimelyUser = {
	email?: string,
	staffCount?: string,
	name?: string,
	planRate?: string,
	fname?: string,
	lname?: string,
	bsize?: string,
	emailJourney?: string,
	website?: string,
	sfrom?: string,
	industry?: string,
	tel?: string,
	telmulti?: string,
	country?: string,
	'main-source'?: string,
	'sub-source'?: string,
	/** If you need to grab geolocation at the start of the page load, use `getLocation` to avoid a potential race condition */
	geolocation?: string,
	webinar?: string,
	businessCategory?: string,
	businessSize?: string,
	'timely-user'?: string,
	'business-name'?: string,
	business_status?: string,
	preferredTime?: string,
	contactID?: string,
	ReferrerID?: string,

	forceCaptcha?: boolean,

	error?: string,
};

export function isTimelyUser(testData: unknown): testData is TimelyUser {
	const data = testData as TimelyUser;

	if (!(data && typeof data === 'object')) {
		return false;
	}

	if (!(
		typeof data.email === 'undefined' ||
		typeof data.email === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.staffCount === 'undefined' ||
		typeof data.staffCount === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.name === 'undefined' ||
		typeof data.name === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.planRate === 'undefined' ||
		typeof data.planRate === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.fname === 'undefined' ||
		typeof data.fname === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.lname === 'undefined' ||
		typeof data.lname === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.bsize === 'undefined' ||
		typeof data.bsize === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.emailJourney === 'undefined' ||
		typeof data.emailJourney === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.website === 'undefined' ||
		typeof data.website === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.sfrom === 'undefined' ||
		typeof data.sfrom === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.industry === 'undefined' ||
		typeof data.industry === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.tel === 'undefined' ||
		typeof data.tel === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.telmulti === 'undefined' ||
		typeof data.telmulti === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.country === 'undefined' ||
		typeof data.country === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data['main-source'] === 'undefined' ||
		typeof data['main-source'] === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data['sub-source'] === 'undefined' ||
		typeof data['sub-source'] === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.geolocation === 'undefined' ||
		typeof data.geolocation === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.webinar === 'undefined' ||
		typeof data.webinar === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.businessCategory === 'undefined' ||
		typeof data.businessCategory === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.businessSize === 'undefined' ||
		typeof data.businessSize === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data['timely-user'] === 'undefined' ||
		typeof data['timely-user'] === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data['business-name'] === 'undefined' ||
		typeof data['business-name'] === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.preferredTime === 'undefined' ||
		typeof data.preferredTime === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.contactID === 'undefined' ||
		typeof data.contactID === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.ReferrerID === 'undefined' ||
		typeof data.ReferrerID === 'string'
	)) {
		return false;
	}

	if (!(
		typeof data.forceCaptcha === 'undefined' ||
		typeof data.forceCaptcha === 'boolean'
	)) {
		return false;
	}

	if (!(
		typeof data.error === 'undefined' ||
		typeof data.error === 'string'
	)) {
		return false;
	}

	return true;
}
