import React from 'react';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { PageProvider } from '../components/context/page-context';

import '../styles/bootstrap_5.min.css';
import '../styles/mach-5.scss';
import 'react-hint/css/index.css';

export default function App({ Component, pageProps }: AppProps) {
    useRouter();
    const defaultUser = {
        geolocation: '',
    };

    return (
        <PageProvider defaultUser={defaultUser}>
            <Component className="page-wrapper" {...pageProps} />
        </PageProvider>
    );
}
