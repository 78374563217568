import { EnumTypeOf } from './types/EnumTypeOf';

let geolocation: string | null = null;
let geolocationHasBeenSet: boolean = false;
let geolocationSetResolutions: ((val: Awaited<ReturnType<typeof getGeolocation>>) => void)[] = [];

/**
 * Regions that an element can be restricted to display within.
 *
 * To provide these options within a field created through WordPress's ACF plugin, use this:
 *
 * ```plain-text
all : All regions
au : Australia
nz : New Zealand
gb : United Kingdom
sg : Singapore
gdpr : Europe (GDPR)
no-gdpr : Non-GDPR regions
tp : TimelyPay regions
tp-eu : Europe (TimelyPay)
no-tp : Non-TimelyPay regions
tp-terminals : TimelyPay Terminals regions
no-tp-terminals : Non-TimelyPay Terminals regions
```
 *
 * Provide this as the description:
 * >>>
<a href="https://gettimely.atlassian.net/wiki/spaces/REV/pages/1896382729/Display+Region+Reference" target="_blank">Display Region Reference</a>
>>>
*/
export const DisplayRegion = {
	ALL: 'all',
	AUSTRALIA: 'au',
	NEW_ZEALAND: 'nz',
	UNITED_KINGDOM: 'gb',
	SINGAPORE: 'sg',

	GDPR: 'gdpr',
	/** Inverse of `DisplayRegion.GDPR` */
	NO_GDPR: 'no-gdpr',

	TIMELY_PAY: 'tp',
	TIMELY_PAY_EUROPE: 'tp-eu',
	/** Inverse of `DisplayRegion.TIMELY_PAY` */
	NO_TIMELY_PAY: 'no-tp',

	TIMELY_PAY_TERMINALS: 'tp-terminals',
	NO_TIMELY_PAY_TERMINALS: 'no-tp-terminals',
} as const;
export type DisplayRegion = EnumTypeOf<typeof DisplayRegion>;

const displayRegionCssClasses: Record<DisplayRegion, string> = {
	[DisplayRegion.ALL]: '',
	[DisplayRegion.AUSTRALIA]: 'au-only',
	[DisplayRegion.NEW_ZEALAND]: 'nz-only',
	[DisplayRegion.UNITED_KINGDOM]: 'gb-only',
	[DisplayRegion.SINGAPORE]: 'sg-only',

	[DisplayRegion.GDPR]: 'gdpr-only',
	[DisplayRegion.NO_GDPR]: 'nogdpr-only',

	[DisplayRegion.TIMELY_PAY]: 'tp-only',
	[DisplayRegion.TIMELY_PAY_EUROPE]: 'tpeu-only',
	[DisplayRegion.NO_TIMELY_PAY]: 'tp-hidden',

	[DisplayRegion.TIMELY_PAY_TERMINALS]: 'tp-terminals-only',
	[DisplayRegion.NO_TIMELY_PAY_TERMINALS]: 'tp-terminals-hidden',
};

/**
 * Converts a `DisplayRegion` value into a CSS class
 * that can be used to restrict an element to only show
 * for the selected regions.
 *
 * For the relevant CSS, see `_geovisible.scss`
*/
export function getDisplayRegionCssClass(region: DisplayRegion): string {
	return displayRegionCssClasses[region];
}

interface DisplayRegionRules {
  /**
   * If inverse is false, the display region is *visible* in all listed regions
   * and *hidden* in all unlisted regions.
   *
   * If inverse is true, the display region is *hidden* in all listed regions
   * and *visible* in all unlisted regions.
   */
	inverse: boolean,
  /**
   * A list of regions that define the behaviour of this display region.
   */
	regions: readonly string[],
}

/** Regions where TimelyPay is available */
const timelyPayRegions = [
	'GB',
	'NZ',
	'AU',
	'AT',
	'BE',
	'BG',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'EA',
	'IC',
	'SE',
] as const;

/** Regions in the EU where TimelyPay is available */
const timelyPayEuRegions = [
	'AT',
	'BE',
	'BG',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'EA',
	'IC',
	'SE',
] as const;

/** Regions where TimelyPay Terminals are available */
const timelyPayTerminalsRegions = [
	'NZ',
  'AU',
  'GB',
] as const;

/** Regions where GDPR applies */
export const gdprRegions = [
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
] as const;

const displayRegionRules: Record<DisplayRegion, DisplayRegionRules> = {
	[DisplayRegion.ALL]: {
		inverse: true,
		regions: [],
	},
	[DisplayRegion.AUSTRALIA]: {
		inverse: false,
		regions: ['AU'],
	},
	[DisplayRegion.NEW_ZEALAND]: {
		inverse: false,
		regions: ['NZ'],
	},
	[DisplayRegion.UNITED_KINGDOM]: {
		inverse: false,
		regions: ['GB'],
	},
  [DisplayRegion.SINGAPORE]: {
    inverse: false,
    regions: ['SG'],
  },

	[DisplayRegion.GDPR]: {
		inverse: false,
		regions: gdprRegions,
	},
	[DisplayRegion.NO_GDPR]: {
		inverse: true,
		regions: gdprRegions,
	},

	[DisplayRegion.TIMELY_PAY]: {
		inverse: false,
		regions: timelyPayRegions,
	},
	[DisplayRegion.TIMELY_PAY_EUROPE]: {
		inverse: false,
		regions: timelyPayEuRegions,
	},
	[DisplayRegion.NO_TIMELY_PAY]: {
		inverse: true,
		regions: timelyPayRegions,
	},
  [DisplayRegion.TIMELY_PAY_TERMINALS]: {
    inverse: false,
    regions: timelyPayTerminalsRegions,
  },
  [DisplayRegion.NO_TIMELY_PAY_TERMINALS]: {
    inverse: true,
    regions: timelyPayTerminalsRegions,
  },
};

/**
 * Checks whether or not the current user is in a given `DisplayRegion`
 */
export async function isInDisplayRegion(region: DisplayRegion): Promise<boolean> {
	const { inverse, regions } = displayRegionRules[region];
	const geolocation = await getGeolocation();

	const isInRegion = geolocation ? regions.includes(geolocation) : false;

	if (inverse) {
		return !isInRegion;
	} else {
		return isInRegion;
	}
}

/**
 * This export allows the current location to be set,
 * so it can be checked later.
 */
export function setGeolocation(region?: string): void {
	geolocation = region?.toUpperCase() ?? null;

	// If this is the first time we've set geolocation,
	// resolve every waiting `Promise` and clear the queue
	if (
		geolocationHasBeenSet === false &&
		geolocationSetResolutions.length > 0
	) {
		geolocationSetResolutions.forEach((resolve) => resolve(geolocation));
		geolocationSetResolutions = [];
	}
	geolocationHasBeenSet = true;
}

/**
 * It's possible that this function may be invoked before
 * the geolocation has been set, so it needs to be async.
 */
function getGeolocation(): Promise<string | null> {
	return new Promise((resolve, reject) => {
		if (geolocationHasBeenSet) {
			resolve(geolocation);
		} else {
			geolocationSetResolutions.push(resolve);
		}
	});
}
