import { Cookie } from '../../lib/constants';

interface CookieSetOptions {
  domain?: string,
}

const cookieManager = {
  /**
   * Get the value of a cookie, if it exists. Otherwise returns `null`.
   */
  get(name: Cookie): string | null {
    if (typeof document === "undefined") {
      return null;
    }

    const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");

    return v ? v[2] : null;
  },

  /**
   * Sets the value of a cookie, with an expiry date specified in days.
   */
  set(name: Cookie, val: string, days: number = 30, options?: CookieSetOptions): void {
    if (typeof document === "undefined") {
      return;
    }

    const cookie =
      `${name}=${val};` +
      `path=/;` +
      (options?.domain ? `domain=${options.domain};` : '') +
      `max-age=${String(3600 * 24 * days)}`;

    document.cookie = cookie;
  },

  /**
   * Convert part or all of a querystring-like string into an object.
   */
  parse_str(str: string): Record<string, string> {
    const obj: Record<string, string> = {};

    str = str.replace('Hair &', 'Hair and');

    const strArr = String(str).replace(/^&/, "").replace(/&$/, "").split("&");
    strArr.forEach((s) => {
      const split = s.split('=');
      const key = split[0];
      let value = split[1];
      value = value.replace('and', '&');
      obj[key] = value;
    });

    return obj;
  },
};

export default cookieManager;
