import { EnumTypeOf } from "./types/EnumTypeOf";

/**
 * A list of all region codes.
 *
 * Subsets can be created following the same pattern. A `const` object is used instead of an `enum` in order to allow for computed string values.
 */
export const Region = {
	ANGORA: 'AO',
	AUSTRALIA: 'AU',
	CANADA: 'CA',
	GERMANY: 'DE',
	GREAT_BRITAIN: 'GB',
	HONG_KONG: 'HK',
	IRELAND: 'IE',
	INDIA: 'IN',
	NETHERLANDS: 'NL',
	NORWAY: 'NO',
	NEW_ZEALAND: 'NZ',
	PUERTO_RICO: 'PR',
	SAUDI_ARABIA: 'SA',
	UNITED_STATES: 'US',
	REST_OF_THE_WORLD: 'REST',

	UNKNOWN: 'Unknown',
} as const;
export type Region = EnumTypeOf<typeof Region>;
