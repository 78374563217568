import { QueryStringParam } from "lib/constants";

/**
 * Convert query string into an object.
 *
 * Only specific query string parameters are permitted. Any new parameters must be added to `QueryStringParam`.
 */
export default function getQueryStringParams(query: string): Partial<Record<QueryStringParam, string>> {
  if (!query) {
    return {};
  }

  if (/^[?#]/.test(query)) {
    query = query.slice(1);
  }

  const paramArr = query.split('&');
  const params: Partial<Record<QueryStringParam, string>> = {};
  for (const param of paramArr) {
    const [key, value] = param.split('=');

    // This type assertion is unsafe, but preferable to excluding unrecognised parameters and potentially introducing bugs
    params[key as QueryStringParam] = value;
  }

  return params;
}
